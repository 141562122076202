@import "core";
@import "fonts";
@import "iconfonts";
@import "mixins";
@import "flexbox";
@import "flags/less/flag-icon";
@import "search";
@import "product-popup";
@import "tippy";
@import "beauty-dropdown";
@import "colors";
@import "theme";
@import "popup-mobile-app";

* {
    margin: 0;
    padding: 0;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
}

.full-width {
    width: 100% !important;
}

.block {
    display: block !important;
}

.error-text {
    color: red;
}

.m-t {
    margin-top: 15px;
}

.m-b {
    margin-bottom: 15px;
}

.clearfix {
    *zoom: 1;
    position: unset;
    &:before,
    &:after {
        display: table;
        content: "";
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

.wrapper {
    background: #f0f2f6;
    width: 100%;
    font-family: Roboto, sans-serif;
}
.main-logo {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .b2b-logo {
        position: relative;
        color: #b8b9ba;
        font-size: 50px;
        font-weight: 300;
        line-height: 66px;
    }
}
.register-logo {
    top: -4px;
    left: 80px;
    display: block;
    width: 145px;
    height: 60px;
    position: relative;
    text-decoration: none;
    background: url("../img/new-logo.png") no-repeat;
    background-size: contain;
    .b2b-logo {
        position: relative;
        color: #b8b9ba;
        font-size: 50px;
        font-weight: 300;
        padding-left: 148px;
        line-height: 66px;
    }
}
.register-logo-cafe {
    top: -12px;
    left: 80px;
    display: block;
    width: 80px;
    height: 80px;
    position: relative;
    text-decoration: none;
    background: url("../img/logo_cafe.png") no-repeat;
    background-size: contain;
}
.content {
    max-width: 1473px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.styled-fancybox .fancybox-skin {
    background: #f9f9f9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: #444;
    position: relative;
    text-shadow: none;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
}

.header {
    width: 100%;
    .advantages {
        list-style: none;
        width: 100%;
        padding: 20px 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        li {
            width: calc(~'100% / 5');
            .wrap_item_advantages {
                width: 170px;
                position: relative;
            }
            .icon_advantages {
                position: absolute;
                top: 0;
                left: 0;
            }
            .icon_delivery {
                background: url("../img/new_layout/car.png") no-repeat;
                width: 41px;
                height: 35px;
                top: 2px;
            }
            .icon_prepayment {
                background: url("../img/new_layout/purse.png") no-repeat;
                width: 41px;
                height: 35px;
                top: -5px;
                left: 10px;
            }
            .icon_discount {
                background: url("../img/new_layout/gift.png") no-repeat;
                width: 41px;
                height: 35px;
                top: 2px;
            }
            .icon_stock {
                background: url("../img/new_layout/stock.png") no-repeat;
                width: 41px;
                height: 35px;
                top: 1px;
                left: 2px;
            }
            .icon_partners {
                background: url("../img/new_layout/hand.png") no-repeat;
                width: 41px;
                height: 35px;
                top: -3px;
                left: 5px;
            }
            .text {
                font-size: 14px;
                color: #4e74bc;
                text-align: left;
                padding-left: 50px;
                position: relative;
                float: left;
            }
        }
    }
    .section_bottom {
        background: #fff;
        padding: 20px 0 40px;
        width: 100%;
        .top_header {
            position: relative;
            .logo {
                float: left;
                width: 145px;
                height: 60px;
                position: relative;
                text-decoration: none;
                background: url("../img/new-logo.png") no-repeat;
                background-size: contain;
                &.ru {
                    background: url("../img/new-logo-whitebg-245.jpg") no-repeat;
                    background-size: contain;
                }
                &.en {
                    background: url("../img/new-logo-whitebg-245.jpg") no-repeat;
                    background-size: contain;
                }
                span {
                    position: relative;
                    color: #b8b9ba;
                    font-size: 50px;
                    font-weight: 300;
                    padding-left: 148px;
                    line-height: 66px;
                }
            }
            .logo_cafe {
                float: left;
                width: 80px;
                height: 80px;
                position: relative;
                text-decoration: none;
                background: url("../img/logo_cafe.png") no-repeat;
                background-size: contain;
            }
            .right-block {
                max-width: 403px;
                width: 100%;
                float: right;
                padding: 22px 0 0 0;
                .lang-block {
                    float: left;
                    .row_country {
                        position: relative;
                        z-index: 11;
                        width: 180px;
                        max-width: max-content;
                    }
                    .row_language {
                        position: relative;
                        width: 33px;
                        top: -3px;
                        margin-right: 5px;
                    }
                }
                .col_list {
                    display: inline-block;
                    .language {
                        margin: 0 20px 0 5px;
                        height: 22px;
                        position: relative;
                        z-index: 10;

                        .country {
                            font-size: 14px;
                            color: #4d4d4d;
                            position: relative;
                            white-space: nowrap;
                            display: block;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                            text-transform: uppercase;
                        }
                        .lan_select {
                            position: relative;
                            cursor: pointer;
                            .country {
                                width: auto;
                                display: inline-block;
                                padding: 0 16px 0 0;
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    right: 2px;
                                    top: 2px;
                                    width: 8px;
                                    height: 8px;
                                    border-top: 1px solid #4d4d4d;
                                    border-right: 1px solid #4d4d4d;
                                    transform: rotate(135deg);
                                }
                            }
                        }
                        .drop_down_lang {
                            display: none;
                        }
                        &.active {
                            z-index: 11;
                            .wrap_language {
                                position: absolute;
                                top: -8px;
                                right: -46px;
                                background-color: rgba(207, 45, 44, .83);
                                box-shadow: 0 1px 4px rgba(0, 0, 0, .4);
                                z-index: 100;
                                .lan_select {
                                    padding: 10px 20px 10px 15px;
                                    color: #fff;
                                    .country {
                                        &:before {
                                            transform: rotate(-45deg);
                                            border-color: #fff;
                                            top: 7px;
                                        }
                                    }
                                }
                                .country {
                                    color: #fff;
                                }
                            }
                            .drop_down_lang {
                                overflow: hidden;
                                display: block;
                                z-index: 100;
                                min-width: 100%;
                                width: auto;
                                .drop_down_list {
                                    list-style: none;
                                    margin-bottom: 0;
                                    .drop_down_item {
                                        display: block;
                                        a {
                                            display: block;
                                            padding: 10px 15px;
                                            text-decoration: none;
                                            color: #fff;
                                            &:hover {
                                                background-color: rgba(0, 0, 0, .3);
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .country_list {
                        margin: 0 14px 5px 5px;
                        height: 22px;
                        position: relative;
                        z-index: 10;
                        .icon_flag {
                            position: absolute;
                            width: 23px;
                            height: 23px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            border-radius: 50%;
                        }
                        .country {
                            font-size: 14px;
                            color: #4d4d4d;
                            padding: 0 16px 0 32px;
                            position: relative;
                            white-space: nowrap;
                            display: block;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                        }
                        .cont_select {
                            position: relative;
                            cursor: pointer;
                            .country {
                                display: inline-block;
                                line-height: 26px;
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    right: 2px;
                                    top: 5px;
                                    width: 8px;
                                    height: 8px;
                                    border-top: 1px solid #4d4d4d;
                                    border-right: 1px solid #4d4d4d;
                                    transform: rotate(135deg);
                                }
                            }
                        }
                        .drop_down_cont {
                            display: none;
                        }
                        &.active {
                            width: 300px;
                            margin: 0 20px 4px -29px;
                            z-index: 11;
                            .wrap_country {
                                position: absolute;
                                top: -10px;
                                right: -21px;
                                background-color: rgba(207, 45, 44, .83);
                                box-shadow: 0 1px 4px rgba(0, 0, 0, .4);
                                z-index: 100;
                                width: 100%;
                                .cont_select {
                                    padding: 10px 20px 10px 13px;
                                    color: #fff;
                                    .country {
                                        &:before {
                                            transform: rotate(-45deg);
                                            border-color: #fff;
                                            top: 10px;
                                        }
                                    }
                                }
                                .country {
                                    color: #fff;
                                }
                            }
                            .drop_down_cont {
                                overflow: hidden;
                                display: block;
                                z-index: 100;
                                min-width: 100%;
                                width: auto;
                                overflow-y: auto;
                                max-height: 500px;
                                &::-webkit-scrollbar {
                                    width: 6px;
                                    background-color: transparent;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background-color: #8c0201;
                                }
                                &::-webkit-scrollbar-track {
                                    background-color: transparent;
                                }
                                .search_country {
                                    width: calc(~'100% - 4px');
                                    border: none;
                                    margin: 0 2px 5px;
                                    font-size: 18px;
                                    color: #000;
                                    padding: 2px 10px;
                                }
                                .search_country_empty {
                                    display: none;
                                    color: #fff;
                                    margin: 5px 13px 10px;
                                    font-size: 16px;
                                }
                                .drop_down_list {
                                    list-style: none;
                                    margin-bottom: 0;
                                    .drop_down_item {
                                        display: block;
                                        a {
                                            display: block;
                                            padding: 10px 5px 10px 13px;
                                            text-decoration: none;
                                            color: #fff;
                                            position: relative;
                                            &:hover {
                                                background-color: rgba(0, 0, 0, .3);
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .phone-block {
                float: right;
                margin-top: -4px;
                .phone {
                    a {
                        color: #4d4d4d;
                        font-size: 20px;
                        font-weight: 400;
                        text-decoration: none;
                    }
                }
                &_mobile {
                    display: none;
                }
            }
            .menu_block {
                float: left;
                margin-left: 110px;
                margin-top: 20px;
                font-size: 14px;
                .top_menu {
                    list-style: none;
                    li {
                        float: left;
                        position: relative;
                        display: inline-block;
                        padding: 5px 10px;
                        &.new_category {
                            margin-right: 20px;
                            .count_new_model {
                                color: #fff;
                                background: #e02729;
                                padding: 1px 5px;
                                display: inline-block;
                                position: absolute;
                                top: 0;
                                right: 10px;
                                font-size: 12px;
                                transform: translate(45%,-45%);
                                border-radius: 2px;
                            }
                        }
                        &.outlet_category {
                            border-radius: 3px;
                            padding: 0;
                            position: relative;
                            margin-right: 20px;
                            background-color: #e02729;
                            border-color: #e02729;
                            &:hover {
                                background-color: #ba2828;
                                border-color: #ba2828;
                            }
                            a {
                                text-decoration: none;
                                color: #fff;
                                padding: 5px 20px;
                                display: inline-block;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                        a {
                            text-decoration: none;
                            color: #4d4d4d;
                            padding: 0 10px;
                            position: unset;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &.select {
                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .bottom_header {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            position: relative;
            .form_login {
                order: 1;
                display: flex;
                flex-direction: row;
                position: relative;

                .button,
                .input {
                    float: left;
                    margin: 0 10px 0 0;
                }
                .wrap_input,
                .wrap_button {
                    float: left;
                }
                .input {
                    position: relative;
                    input {
                        width: 260px;
                        height: 50px;
                        padding: 10px 20px;
                        font-size: 18px;
                        color: #828282;
                        border: 2px solid #E5E5E5;
                        &.input_password {
                            width: 290px;
                            padding-right: 120px;
                        }
                        &::-webkit-input-placeholder {
                            color: fade(#828282, 50%);
                        }
                        &::-moz-placeholder {
                            color: fade(#828282, 50%);
                        }
                    }
                    .placeholder {
                        position: absolute;
                        color: #828282;
                        background: #fff;
                        padding: 0 3px;
                        top: 50%;
                        left: 20px;
                        font-size: 18px;
                        transform: translate(0, -50%);
                        .transition();
                    }
                    .error {
                        display: none;
                        color: #e02729;
                        font-size: 11px;
                        position: absolute;
                        top: 50px;
                        left: 0;
                        line-height: 12px;
                    }
                    &.focus,
                    &.full {
                        .placeholder {
                            top: 0;
                            left: 10px;
                            font-size: 14px;
                            .transition();
                        }
                    }
                    &.error {
                        input {
                            border-color: #e02729;
                        }
                        .placeholder {
                            color: #e02729;
                        }
                        .error {
                            display: block;
                        }
                    }
                    &.success {
                        input {
                            border-color: #87c109;
                        }
                        .placeholder {
                            color: #87c109;
                        }
                    }
                    .memory_password {
                        font-size: 14px;
                        color: #828282;
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translate(0, -50%);
                        max-width: 140px;
                        text-align: right;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    .iti {
                        .iti__search-input {
                            width: 100%;
                            border-width: 1px;
                            border-radius: 0;
                            height: 35px;
                            padding: 10px 10px;
                            font-size: 16px;
                        }
                        .input_phone {
                            padding: 10px 16px !important;
                            width: 170px;
                        }
                    }
                }
                .button {
                    text-align: center;
                    button,
                    a {
                        height: 50px;
                        width: auto;
                        min-width: 160px;
                        font-size: 14px;
                        padding: 0 10px;
                        display: inline-block;
                        background: #e02729;
                        text-decoration: none;
                        color: #fff;
                        float: left;
                        margin-bottom: 15px;
                        border: none;
                        .transition(background);
                        &:hover {
                            background: #ba2828;
                            .transition(background);
                        }
                        &.register {
                            background: #1AB394;
                            padding: 16px 30px;
                            &:hover {
                                background: #158f76;
                            }
                        }
                    }
                }
                .error-text {
                    position: absolute;
                    top: 0;
                    left: 1px;
                    transform: translate(0, -100%);
                }
            }
            .user_authorized {
                margin: 0 auto;
                text-align: center;
                width: calc(~'100% - 500px');
                font-size: 20px;
                order: 1;
                span {
                    font-weight: 500;
                    max-width: 400px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    white-space: nowrap;
                    line-height: 21px;
                    position: relative;
                    top: 4px;
                }
                .welcome_text,
                .wrap_button {
                    display: inline-block;
                }
                .logout,
                .link_home {
                    display: inline-block;
                    padding: 12px 25px;
                    margin: 0 0 0 15px;
                    text-decoration: none;
                    color: #fff;
                    font-size: 18px;
                    background: #e02729;
                    .transition(background, 500ms);
                    &:hover {
                        background: #ba2828;
                        .transition(background, 500ms);
                    }
                }
                .link_home {
                    background: #5745ff;
                    .transition(background, 500ms);
                    &:hover {
                        background: #4536ce;
                        .transition(background, 500ms);
                    }
                }
            }
        }
    }
}

.main_content {
    padding-top: 50px;
    &.index {
        width: 100%;
        background: #ffffff;
        padding-top: 0;
        padding-bottom: 50px;
    }
}

.button-go {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    background: #fff;
    margin: 25px 0 0 0;
    bottom: 20px;
    left: 30px;
    opacity: 1;
    .transition(opacity);

    a {
        display: inline-block;
        padding: 15px 40px;
        text-decoration: none;
        color: #000;
        font-size: 16px;
    }

    &:hover {
        opacity: 0.8;
        .transition(opacity);
    }

    &.black {
        background: #2D2C31;
        a {
            color: #EBE8E3;
        }
    }
}

.text_block {
    font-family: 'Forum', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
    color: #FFFFFF;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 2;
    top: 169px;
}
&.black-text {
    color: #29282D;
}

.first_screen {
    width: 100%;
    .left {
        float: left;
        width: 70%;
        .button {
            bottom: 160px;
            left: 137px;
        }
        .slider_block {
            position: relative;
            overflow: hidden;
            .wrap_slider_block {
                width: 100%;
                padding-bottom: 40px;
                height: 505px;
                box-sizing: content-box;
                overflow: hidden;
                .slider_image {
                    display: inline-block;
                    width: 100%;
                    max-height: 505px;
                    height: 505px;
                    position: relative;
                    overflow: hidden;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    .text {
                        position: absolute;
                        top: 50%;
                        left: 47%;
                        transform: translate(-50%, -50%);
                        font-size: 50px;
                        font-weight: 300;
                        color: #271508;
                        z-index: 1;
                        width: 45%;
                        margin: 0 auto;
                        text-align: center;
                        .strong {
                            font-weight: 700;
                            color: #ff0f23;
                        }
                    }
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                    &.slider_image2 {
                        background-size: contain;
                        background-repeat: no-repeat;
                        .text {
                            top: 52%;
                            left: 28%;
                            font-size: 75px;
                            line-height: 75px;
                            .slider_button {
                                color: #271508;
                                font-size: 14px;
                                line-height: 20px;
                                display: inline-block;
                                position: relative;
                                top: -20px;
                                left: -5px;
                                letter-spacing: 2px;
                                padding: 10px 40px;
                                border: 1px solid #271508;
                                cursor: pointer;
                                text-decoration: none;
                                .transition(background);
                            }
                        }
                        &:hover {
                            .slider_button {
                                background: rgba(39, 21, 8, 0.15);
                            }
                        }
                    }
                    &.slider_image5 {
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
                .slick-dots {
                    bottom: 0;
                    li {
                        button:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: "";
                            width: 12px;
                            height: 12px;
                            font-size: 6px;
                            line-height: 20px;
                            text-align: center;
                            border-radius: 50%;
                            border: 1px solid transparent;
                            background: #dfdfdf;
                            opacity: 1;
                        }
                        &.slick-active {
                            button:before {
                                border: 1px solid #f66819;
                                background: transparent;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .right {
        float: right;
        width: calc(~'30% - 5px');
        .time_block {
            background: url("../public/new_layout/m245.jpg") no-repeat 100% 0;
            background-size: cover;
            padding: 30px;
            height: 250px;
            position: relative;
            overflow: hidden;
            margin: 0 0 5px 0;
        }
        .exclusive_block {
            padding: 30px;
            height: 250px;
            background: url("../public/new_layout/m246.jpg") no-repeat 100% 100%;
            background-size: cover;
            position: relative;
            .text_block {
                color: #fff;
            }
        }
        .partners_block {
            margin: 10px 5px;
            ul {
                list-style: none;
                position: relative;
                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-bottom: 32px;
                    .img {
                        width: 50px;
                        height: 50px;
                        margin-right: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .text {
                        font-size: 22px;
                        line-height: 26px;
                        color: #333333;
                    }
                }
            }
        }
        .text_block {
            top: 130px;
        }
    }
}

.section_woman,
.section_man,
.section_kids {
    margin: 50px 0;
    position: relative;
    h3 {
        width: 100%;
        color: #e02729;
        font-size: 30px;
        text-align: center;
        padding-right: 150px;
        display: inline-block;
    }
    .icon_section {
        position: relative;
    }
    .icon_woman,
    .icon_man,
    .icon_kids {
        position: absolute;
        bottom: -5px;
        right: 0;
        margin-right: 10px;
    }
    .icon_woman {
        width: 145px;
        height: 60px;
        background: url("../img/new_layout/logo_woman.png") no-repeat;
        background-size: contain;
        &.ru {
            background: url("../img/new_layout/logo_woman_ru.png") no-repeat;
            background-size: contain;
        }
        &.en {
            background: url("../img/new_layout/logo_woman_en.png") no-repeat;
            background-size: contain;
        }
    }
    .icon_man {
        width: 185px;
        height: 34px;
        background: url("../img/new_layout/logo_man.png") no-repeat;
        background-size: contain;
        &.ru {
            background: url("../img/new_layout/logo_man_ru.png") no-repeat;
            background-size: contain;
        }
        &.en {
            background: url("../img/new_layout/logo_man_en.png") no-repeat;
            background-size: contain;
        }
    }
    .icon_kids {
        width: 210px;
        height: 54px;
        background: url("../img/new_layout/logo_kids_en.png") no-repeat;
        background-size: contain;
        &.ru {
            background: url("../img/new_layout/logo_kids_ru.png") no-repeat;
            background-size: contain;
        }
        &.en {
            background: url("../img/new_layout/logo_kids_en.png") no-repeat;
            background-size: contain;
        }
    }
    .row_section {
        margin: 20px -10px 20px 0;
        .woman_block_first,
        .man_block_first,
        .kids_block_first {
            overflow: hidden;
            float: left;
            height: 412px;
            width: calc(~'(100% / 2) - 10px');
            margin: 0 10px 10px 0;
            position: relative;

            .text_block {
                top: 190px;
                left: 70px;
            }
            .button-go {
                top: 210px;
                bottom: unset;
                left: 70px;
                z-index: 2;
            }
            &:hover {
                box-shadow: 0 0 10px -3px rgba(0, 0, 0, .4);
            }
        }
        .item {
            overflow: hidden;
            display: inline-block;
            float: left;
            height: 202px;
            max-width: 100%;
            width: calc(~'(100% / 4) - 10px');
            margin: 0 10px 10px 0;
            position: relative;
            text-align: center;
            &:hover {
                box-shadow: 0 0 10px -3px rgba(0, 0, 0, .4);
            }
            a {
                text-decoration: none;
                display: block;
                height: 100%;
            }
            .name {
                font-size: 25px;
                font-family: 'Forum', sans-serif;
                font-weight: 400;
                position: absolute;
                bottom: 15px;
                left: 50%;
                width: 100%;
                z-index: 2;
                text-decoration: none;
                color: #EBE9E5;
                transform: translate(-50%, 0);
            }

            &.vertical2_block {
                height: 413px;
                float: right;
            }
        }

        img {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
        }
    }
}

.footer {
    padding: 30px 0;
    .left {
        float: left;
        .logo_grey {
            width: 145px;
            height: 60px;
            background: url("../img/new_layout/logo_gray.png") no-repeat;
            background-size: contain;
            &.ru {
                background: url("../img/new_layout/logo_gray_ru.png") no-repeat;
                background-size: contain;
            }
            &.en {
                background: url("../img/new_layout/logo_gray_en.png") no-repeat;
                background-size: contain;
            }
        }
        .logo_grey_cafe {
            width: 80px;
            height: 80px;
            background: url("../img/new_layout/logo_gray_cafe.png") no-repeat;
            background-size: contain;
        }
    }
    .right {
        float: right;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #4d4d4d;
        .copyright {
            font-size: 20px;
        }
        .footer_phone {
            text-align: right;
            font-size: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            margin-left: 60px;
            a {
                color: #4d4d4d;
                text-decoration: none;
                float: right;
                display: block;
            }
        }
        .footer_delaweb {
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 40px;
            a {
                border-bottom: 2px dashed #4d4d4d;
                width: 65%;
                &:hover {
                    border-bottom-color: transparent;
                }

                svg {
                    margin-bottom: -6px;
                    width: 100%;
                }
            }
        }
    }
}

.leftAbout {
    float: left;
    width: 50%;
    padding: 10px;
    .title {
        color: #1c1c1c;
        font-size: 40px;
        margin: 0 0 30px 0;
    }
    .small_line {
        width: 51px;
        height: 4px;
        background: #f02121;
        border: none;
    }
    .description {
        color: #1c1c1c;
        font-weight: 500;
        font-size: 18px;
        margin: 30px 0 40px 0;
        p {
            margin-bottom: 20px;
        }
    }
    .item_about {
        float: left;
        width: 50%;
        padding: 5px 15px 5px 5px;
        margin-bottom: 50px;
        .item_title {
            width: 100%;
            float: left;
            .square {
                float: left;
                border: 5px solid #f02121;
                width: 36px;
                height: 36px;
                margin: 10px 10px 10px 0;
            }
            .item_title1 {
                float: left;
                width: calc(~'100% - 46px');
                color: #f02121;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
                padding: 0 10px 10px 20px;
            }
            .item_title2 {
                float: left;
                width: calc(~'100% - 46px');
                color: #595757;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 20px;
                padding: 0 10px 20px 20px;
            }
        }
        .item_desc {
            width: 100%;
            float: left;
            color: #7a7979;
            font-weight: 500;
            font-size: 18px;
        }
    }
}

.rightAbout {
    float: right;
    width: 50%;
    .img {
        width: 100%;
        padding: 20px 0 10px 10px;
        img {
            width: 100%;
        }
    }
}

.leftMenu {
    float: left;
    width: 20%;
    margin: 0 70px 0 30px;
    padding-top: 20px;
    .item_list_menu {
        border-top: 1px solid #95a4b2;
        border-bottom: 1px solid #95a4b2;
        padding: 30px 0;
        margin: 0 0 20px 0;
        list-style-type: none;
        .item_menu {
            padding: 10px 0;
            font-size: 20px;
            .change_item {
                text-decoration: none;
                color: #0e0d0d;
                &:hover {
                    font-weight: 700;
                }
            }
            &.active {
                .change_item {
                    font-weight: 700;
                }
            }
        }
    }
}

.rightContent {
    float: right;
    margin: 0 30px;
    width: calc(~'80% - 160px');
    .item_content {
        display: none;
        .item_title {
            color: #1c1c1c;
            font-size: 36px;
            padding: 0 0 20px 0;
        }
        .item_text {
            font-size: 18px;
            font-weight: 300;
            .subtitle {
                color: #545454;
                font-size: 24px;
                font-weight: 500;
                padding-bottom: 20px;
            }
            li {
                padding-bottom: 0;
                margin-left: 20px;
            }
            p {
                padding-bottom: 20px;
            }
            .main_list {
                font-size: 18px;
                font-weight: 300;
                & > li {
                    list-style-type: none;
                    counter-increment: item;
                    padding-bottom: 0;
                    margin-left: 0;
                    &:before {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 24px;
                        padding-right: 5px;
                        content: "§ " counter(item);
                    }
                    .sub_list {
                        & > li {
                            list-style-type: none;
                            counter-increment: item1;
                            &:before {
                                display: inline-block;
                                font-size: 18px;
                                font-weight: 300;
                                padding-right: 5px;
                                content: counter(item1) ".";
                            }
                        }
                    }
                }
                .chapter {
                    font-weight: 500;
                    padding-bottom: 20px;
                    display: inline-block;
                }
            }
        }
        &.active {
            display: block;
        }
    }
}

#fancybox_popup {
    .btn-primary {
        background-color: #E86565;
        border-color: #E86565;
        &:hover,
        &:focus,
        &:active,
        &.active,
        &:active:focus,
        &:active:hover,
        &.active:hover,
        &.active:focus {
            background-color: #DF2424;
            border-color: #DF2424;
        }
    }
    .text-muted {
        color: #888888;
    }
    .btn-white {
        color: inherit;
        background: white;
        border: 1px solid #e7eaec;
        &:hover,
        &:focus,
        &:active,
        &.active,
        &:active:focus,
        &:active:hover,
        &.active:hover,
        &.active:focus {
            color: inherit;
            border: 1px solid #d2d2d2;
        }
        &:active,
        &.active {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
        }
    }
    .btn {
        border-radius: 3px;
    }
    .form-control,
    .single-line {
        background-color: #FFFFFF;
        background-image: none;
        border: 1px solid #e5e6e7;
        border-radius: 1px;
        color: inherit;
        display: block;
        padding: 6px 12px;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        box-shadow: none;
        position: relative;
        &:focus {
            border-color: #1ab394 !important;
        }
    }
    .help-bubble {
        visibility: hidden;
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 320px;
        background-color: white;
        min-height: 50px;
        margin-bottom: 36px;
        opacity: 0;
        margin-left: -160px;
        padding: 15px;
        z-index: 2;
        filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
        .transition(all, 700ms, 200ms);
        &:before {
            content: "";
            border: 10px solid transparent;
            border-top-color: white;
            position: absolute;
            left: 50%;
            top: 100%;
            margin-left: -10px;
        }
        &.show {
            visibility: visible;
            margin-bottom: 6px;
            opacity: 1;
        }
    }
    .chosen-container {
        text-align: left;
        width: 100% !important;
        .chosen-single {
            outline: none !important;
            div b {
                background-position: 3px 7px;
            }
        }
        .chosen-results li.highlighted {
            background: #DF2424;
        }
        &.chosen-container-active {
            &.chosen-with-drop {
                .chosen-single {
                    background: white;
                    border: 1px solid #aaa;
                    div b {
                        background-position: -15px 6px;
                    }
                }
                .chosen-drop {
                    left: 0;
                    z-index: 1000000;
                }
            }
        }
    }
    .chosen-container-single .chosen-single,
    .chosen-container-multi .chosen-choices {
        background: #ffffff;
        box-shadow: none;
        border: 1px solid #e5e6e7;
        border-radius: 2px;
        cursor: text;
        height: auto !important;
        margin: 0;
        min-height: 30px;
        overflow: hidden;
        padding: 4px 12px;
        position: relative;
        width: 100%;
        span,
        li.search-field input[type=text] {
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            position: relative;
            top: 1px;
        }
    }
}

.row_wrap_contact {
    .item_contact {
        float: left;
        width: 40%;
        margin: 20px 5%;
        font-size: 18px;
    }
    .title {
        font-size: 20px;
        margin: 0 0 10px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 30px;
    }
    .description {
        color: #545454;
    }
    .contact-container {
        float: left;
        padding-left: 20px;
        width: calc(~'100% - 30px');
    }
    .icon-container {
        float: left;
        width: 30px;
        .icon {
            color: rgb(255, 54, 54);
            .transition(color, 200ms);
            font-size: 30px;
        }
    }
}
.class {
    color: #fff;
}

.iti__flag {background-image: url("../../node_modules/intl-tel-input/build/img/flags.png");}

@media (min-resolution: 2x) {
    .iti__flag {background-image: url("../../node_modules/intl-tel-input/build/img/flags@2x.png");}
}

.iti .iti__country-name {
    text-align: left;
}

.iti--show-selected-dial-code .iti__selected-flag {
    background-color: #fff;
}

header {
    .iti__flag-container {
        position: relative;
        display: inline-block;
        height: 50px;
        border: 2px solid #E5E5E5;
        margin-right: 10px;
        float: left;
        padding: 0;
    }
    .iti__selected-flag {
        padding: 0 10px;
    }
}

.back {
    font-size: 20px;
    position: absolute;
    top: 40px;
    left: 50px;
    a {
        display: flex;
        align-items: center;
        color: @primaryColor;
        background: none;
        border: none;
        svg {
            margin-right: 10px;
            path {
                stroke: @primaryColor;
            }
        }
        &:focus,
        &:hover {
            color: @primaryColorLight;
        }
    }
    &:focus,
    &:hover {
        svg {
            path {
                stroke: @primaryColorLight;
            }
        }
    }
}

.reset_password {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: underline;
    display: block;
    &:focus,
    &:hover {
        color: #000;
        text-decoration: none;
    }
}

.login_for_mail_or_phone {
    font-weight: 400;
    font-size: 14px;
    color: #EA2126;
    text-decoration: underline;
    display: block;
    &:focus,
    &:hover {
        color: #EA2126;
        text-decoration: none;
    }
}

.wrap_btn_register {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: #000;
    font-size: 16px;
    a {
        color: #EA2126;
        text-decoration: underline;
        &:focus,
        &:hover {
            text-decoration: none;
            color: #EA2126;
        }
    }
}

.promo-feedback {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;

    a {
        color: #EA2126;
        text-decoration: underline;
        &:focus,
        &:hover {
            text-decoration: none;
            color: #EA2126;
        }
    }
}

.loginscreen {
    &.passwordBox {
        padding-top: 40px;
    }
    &.middle-box {
        max-width: 100%;
        &.login-select-user {
            width: 375px;
        }
        &.login-input-code {
            width: 450px;
            .login_by_phone {
                width: 290px;
                margin: 0 auto;
            }
        }
        h1 {
            font-size: 32px;
            font-weight: 400;
            color: #000;
        }
    }
    .form-control {
        height: 50px;
    }
    .input {
        &.error {
            input {
                border-color: #e02729;
            }
        }
        &.success {
            input {
                border-color: #87c109;
            }
        }
    }
    .chosen-container {
        .chosen-single {
            height: 50px !important;
            line-height: 37px;
            div b {
                background-position: 3px 15px;
            }
        }
        &.chosen-container-active.chosen-with-drop {
            .chosen-single {
                div b {
                    background-position: -15px 15px;
                }
            }
        }
    }
    .wrap_input_code {
        display: flex;
        justify-content: center;
        gap: 8px;
        .input_code {
            height: 43px;
            width: 43px;
            font-family: monospace;
            font-size: 20px;
            border: 1px solid #E5E5E5;
            text-align: center;
            &:focus {
                border: 1px solid #808080 !important;
            }
        }
    }
    .title_description {
        font-size: 16px;
        a {
            color: @primaryColor;
            text-decoration: underline;
            &:hover {
                color: @primaryColorLight;
                text-decoration: none;
            }
        }
        span {
            color: #000;
        }
    }
    .btn {
        border-radius: 0;
        height: 50px;
        font-size: 20px;
    }
    .iti {
        display: flex;
        .iti__flag-container {
            position: relative;
            display: inline-block;
            height: 50px;
            border: 1px solid #E5E5E5;
            margin-right: 8px;
            float: left;
            padding: 0;
        }
        .iti__selected-flag {
            padding: 0 24px;
        }
        .input_phone {
            padding: 10px 16px !important;
            width: 100%;
            border: 1px solid #e5e5e5;
            color: #828282;
            font-size: 18px;
            height: 50px;
        }
    }

    .resend_link,
    .resend_time,
    .police {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.4);
        button,
        a {
            color: rgba(0, 0, 0, 0.4);
            text-decoration: underline;
            border: none;
            background: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .resend_time {
        font-size: 12px;
    }
    .resend_link {
        font-size: 16px;
    }
}

input:focus::-webkit-input-placeholder {opacity: 0;}
input:focus::-moz-placeholder          {opacity: 0;}
input:focus:-moz-placeholder           {opacity: 0;}
input:focus:-ms-input-placeholder      {opacity: 0;}

.form_by_select_users {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.wrap_select_user {
    display: flex;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 16px;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin: 0;
    position: relative;

    &:hover:not(.user_not_active) {
        cursor: pointer;
        background: #f7f7f7;
        border-color: #C94942
    }

    &.user_not_active {
        background: rgba(229, 229, 229, 0.5);
        border: 1px solid rgba(231, 231, 231, 0.5);
        div:not(.select_user_info) {
            opacity: 50%;
        }
        .select_user_email,
        .select_user_name {
            max-width: 265px;
        }
    }

    input {
        display: none;
    }

    .initial {
        background: #C94942;
        color: #fff;
        font-weight: 300;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .select_user_name {
        color: rgba(0, 0, 0, 0.6);
    }

    .select_user_email,
    .select_user_name {
        max-width: 285px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select_user_info {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        height: 23px;
        width: 23px;
    }
}

.register {
    .title_description {
        font-size: 16px;
        color: #EA2126;
        span {
            color: #EA2126;
            font-size: 24px;
        }
    }
    .form-group {
        select:invalid {
            height: 0 !important;
            opacity: 0 !important;
            position: absolute !important;
            display: flex !important;
            top: 64px !important;
        }
        select.not_supported {
            height: 50px !important;
            opacity: 1 !important;
            position: initial !important;
            display: initial !important;
            top: 0 !important;
            margin: 0;
        }
        label {
            font-weight: 400;
            font-size: 16px;
            text-align: left;
            display: block;
            .not_required {
                font-size: 12px;
                opacity: 0.6;
            }
        }
        &.error {
            .chosen-single,
            input {
                border-color: #EA2126;
            }
        }
    }
    .error-text {
        text-align: left;
        font-size: 12px;
    }
}

@media (max-width: 1380px) {
    .header {
        .section_bottom {
            .bottom_header {
                .form_login.login_by_email {
                    .button,
                    .input {
                        float: none;
                        margin-bottom: 15px;
                    }
                    .input {
                        input {
                            width: 290px;
                        }
                    }
                    .button.btn_login {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .first_screen {
        .left,
        .right {
            float: none;
            width: 100%;
        }
        .right {
            .partners_block,
            .time_block,
            .exclusive_block {
                float: left;
                width: 49%;
            }
            .time_block {
                float: left;
            }
            .partners_block,
            .exclusive_block {
                float: right;
            }
        }
        .left {
            .slider_block {
                .wrap_slider_block {
                    .slider_image {
                        &.slider_image2 {
                            .text {
                                left: 33%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .section_kids,
    .section_man,
    .section_woman {
        .row_section {
            .man_block_first,
            .kids_block_first,
            .woman_block_first {
                width: calc(~'(100% / 3 * 2) - 10px');
            }
            .item {
                width: calc(~'(100% / 3) - 10px');
            }
        }
    }

    .first_screen {
        .right {
            .partners_block,
            .time_block,
            .exclusive_block {
                width: calc(~'50% - 10px');
            }
        }
    }
}

@media (max-width: 905px) {
    .header {
        .section_top {
            display: none;
        }
        .section_bottom {
            .top_header {
                margin-bottom: 20px;
            }
        }
    }

    .rightAbout,
    .leftAbout,
    .leftMenu,
    .rightContent {
        float: none;
        width: 100%;
    }

    .leftMenu,
    .rightContent {
        padding-right: 30px;
        padding-left: 30px;
        margin: 0;
    }

    .rightAbout {
        .img {
            padding: 0 0 20px 0;
        }
    }

    .section_kids,
    .section_man,
    .section_woman {
        .row_section {
            .man_block_first,
            .kids_block_first,
            .woman_block_first {
                width: calc(~'100% - 10px');
            }
        }
    }
}

@media (max-width: 880px) {
    .header {
        .section_bottom {
            padding: 20px 0 10px;
            .top_header {
                margin-bottom: 0;
                .logo {
                    width: 168px;
                    height: 40px;
                    span {
                        font-size: 35px;
                        padding-left: 105px;
                        line-height: 44px;
                    }
                }
                .right-block {
                    padding: 8px 0 0 0;
                }
                .menu_block {
                    margin: 10px 0 0 0;
                    display: block;
                    width: 100%;
                    .top_menu {
                        text-align: center;
                        li {
                            padding: 15px 5px;
                            display: inline;
                            float: none;
                            &.new_category {
                                display: inline-block;
                                .count_new_model {
                                    transform: translate(45%, 0);
                                }
                            }
                        }
                    }
                }
                .phone-block {
                    margin-top: -1px;

                    .phone {
                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
            .bottom_header {
                flex-direction: column;
                margin-top: 5px;
                margin-bottom: 15px;

                .form_login {
                    order: 2;
                    margin: 30px 0 0 0;
                    width: 100%;

                    .wrap_input {
                        display: none;
                    }
                    .wrap_button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                    .button {
                        margin: 0 10px 15px !important;
                        float: left;
                        width: auto;
                        a {
                            width: 100% !important;
                            margin: 0;
                        }
                    }
                }
                .user_authorized {
                    margin: 20px 0 0 0;
                    width: 100%;
                    order: 2;
                    span {
                        max-width: 300px;
                    }
                    .welcome_text {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 770px) {
    .first_screen {
        .left {
            .slider_block {
                .wrap_slider_block {
                    .slider_image {
                        .text {
                            width: 60%;
                        }
                        &.slider_image2 {
                            .text {
                                left: 30%;
                                font-size: 60px;
                                line-height: 65px;
                            }
                        }
                    }
                }
            }
        }
        .right {
            .partners_block,
            .time_block,
            .exclusive_block {
                width: 100%;
                float: none;
            }

            .exclusive_block,
            .time_block {
                padding: 20px;

                .button-go {
                    left: 20px;
                }
            }
        }
    }
    .section_kids,
    .section_man,
    .section_woman {
        .row_section {
            .man_block_first,
            .kids_block_first,
            .woman_block_first {
                width: calc(~'100% - 10px');
                .button-go {
                    left: 20px;
                }
                .text_block {
                    left: 20px;
                }
            }
            .item {
                width: calc(~'(100% / 2) - 10px');
            }
        }
    }

    .row_wrap_contact {
        .item_contact {
            float: none;
            width: calc(~'100% - 40px');
            margin: 20px;
            font-size: 18px;
        }
    }
}

@media (max-width: 690px) {
    .first_screen {
        .left {
            .slider_block {
                .wrap_slider_block {
                    .slider_image {
                        .text {
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
    .section_kids,
    .section_man,
    .section_woman {
        .row_section {
            .man_block_first,
            .kids_block_first,
            .woman_block_first {
                width: calc(~'100% - 10px');
            }
        }
    }
}

@media (max-width: 640px) {
    .header {
        .section_bottom {
            .top_header {
                .right-block {
                    width: 100%;
                    max-width: 100%;
                    padding-top: 15px;

                    .lang-block {
                        float: none;
                        text-align: center;
                        .row_country,
                        .row_language {
                            text-align: left;
                        }
                    }
                }
                .phone-block {
                    &_mobile {
                        display: inline-block;
                        padding-top: 8px;
                    }
                    &_desktop {
                        display: none;
                    }
                }
            }
        }
    }

    .footer {
        .right {
            .footer_phone {
                margin-left: 30px;
            }
        }
    }
}

@media (max-width: 610px) {
    .footer {
        .left {
            float: none;
            .logo_grey,
            .logo_grey_cafe {
                margin: 0 auto;
            }
        }
        .right {
            float: none;
            flex-direction: column;
            .footer_phone {
                align-items: center;
                margin: 0;
            }
            .footer_delaweb {
                align-items: center;
                order: 1;
                margin: 10px 0 0;
            }
        }
    }
}

@media (max-width: 590px) {
    .section_kids,
    .section_man,
    .section_woman {
        h3 {
            font-size: 20px;
            padding-right: 160px;
        }
        .row_section {
            .man_block_first,
            .kids_block_first,
            .woman_block_first {
                width: calc(~'100% - 10px');
                height: 290px;

                .button-go {
                    top: unset;
                    bottom: 20px;
                }
                .text_block {
                    top: unset;
                    bottom: 100px;
                }
            }
        }
        .icon_man,
        .icon_kids {
            width: 150px;
        }
    }

    .first_screen {
        .left {
            .slider_block {
                .wrap_slider_block {
                    height: 200px;
                    .slider_image {
                        height: 200px;
                        .text {
                            font-size: 24px;
                            width: 48%;
                        }
                        &.slider_image2 {
                            .text {
                                left: 35%;
                                font-size: 30px;
                                line-height: 35px;
                                .slider_button {
                                    font-size: 12px;
                                    padding: 6px 25px;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .right {
            .text_block {
                top: 30%;
                font-size: 20px;
            }
            .partners_block,
            .time_block,
            .exclusive_block {
                width: calc(~'100% - 10px');
                float: none;
                height: 180px;
            }
            .partners_block {
                height: auto;
                ul {
                    li {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .section_kids,
    .section_man,
    .section_woman {
        .row_section {
            .item {
                width: calc(~'100% - 10px');

                &.vertical2_block {
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 490px) {
    .first_screen {
        .left {
            .slider_block {
                .wrap_slider_block {
                    .slider_image {
                        .text {
                            width: 55%;
                        }
                        &.slider_image2 {
                            .text {
                                left: 32%;
                                font-size: 25px;
                                line-height: 30px;
                                .slider_button {
                                    padding: 5px 20px;
                                    top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .leftAbout {
        .item_about {
            width: 100%;
        }
    }

    .leftMenu,
    .rightContent {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (max-width: 480px) {
    .header {
        .section_bottom {
            .top_header {
                .right-block {
                    .lang-block {
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media (max-width: 410px) {
    .header {
        .section_bottom {
            .top_header {
                .logo {
                    width: 100%;
                    text-align: center;
                    background-position: 35% 50%;
                    margin-bottom: 20px;

                    span {
                        padding-left: 112px;
                    }
                }

                .phone-block {
                    float: none;
                    text-align: center;
                    margin: 0 0 10px;
                    clear: both;
                    &_mobile {
                        display: block;
                        padding-top: 5px;
                    }
                }

                .right-block {
                    padding: 0;

                    .lang-block {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .header {
        .section_bottom {
            .top_header {
                .menu_block {
                    .top_menu {
                        li {
                            a {
                                padding: 0 4px;
                            }
                        }
                    }
                }
            }
            .bottom_header {
                margin-top: 0;
                .form_login {
                    .button {
                        a {
                            height: 50px;
                            min-width: 120px;
                            padding: 10px 10px;
                            margin: 0;
                        }
                    }
                }
                .user_authorized {
                    span {
                        max-width: 290px;
                    }
                    .link_home,
                    .logout {
                        margin: 0;
                    }
                }
            }
        }
    }

    .button-go {
        a {
            min-width: 120px;
            padding: 10px;
            font-size: 16px;
            text-align: center;
        }
    }

    .section_kids,
    .section_man,
    .section_woman {
        .row_section {
            .man_block_first,
            .kids_block_first,
            .woman_block_first {
                width: calc(~'100% - 10px');
                height: 290px;
            }
        }
    }
}

// apps popup scaling fixes (for mobile phones)
@media only screen and (pointer:coarse) and (max-device-width: 767px) {
    .popup-overlay {
        .popup-mobile-apps {
            padding: 20px 20% 40px;

            &__stores-info {
                .stores-info__logo {
                    height: 63px;
                }

                .stores-info__text {
                    font-size: 10px;
                }
            }

            &__recommendation {
                margin-top: 30px;
                font-size: 18px;
            }

            &__right-block {
                .popup-mobile-apps__action-button {
                    margin-top: 15px;
                }

                .action-button {
                    padding: 15px 40px;
                    font-size: 14px;
                }
            }

            .close-button {
                width: 16px;
                height: 16px;
                line-height: 16px;
                font-size: 30px;
            }
        }
    }
}
